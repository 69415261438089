<template lang="pug">
  .UsersNames
    .mx-5.px-5
      .mt-5.transition.max-w-sm.mx-auto.bg-white.rounded-xl.shadow-bx-small-sm.p-5.text-center.pt-3
        h2.text-primary.text-2xl.m-0.font-semibold Agendamiento de citas
        div
          p.mt-1.text-info_color.m-0 Selecciona el usuario que va a solicitar el servicio
          div.mt-4.mx-auto.Arrow.rounded-bd-complete.w-10.h-10.flex.items-center.justify-center.border-none.outline-none
            span.material-icons.text-primary arrow_downward
    .mx-5
      div(v-if="usersNames.length").buttons.mt-10
        button-component.font-semibold(
          v-for="(userName, index) in usersNames"
          :key="index"
          :text="userName.name"
          :onClick="() => handleSelectName(userName)"
          themeSelected="light"
          class="hover:bg-secondary hover:text-white hover:font-thin"
          :class="`button-${index + 1}`"
        ).mb-10.btn.transition.duration-300
    footer.Footer.fixed.p-5.pt-2.max-w-lg.w-full.bg-white
      button-component(
        text="Continuar con otro usuario"
        themeSelected="secondary"
        :onClick="handleContinue"
        :disabled="disabledContinue"
      )
</template>

<script>
import { mapActions, mapState } from "vuex";
import loadingMessage from "../utils/loadingMessage";
export default {
  name: "UsersNames",
  data() {
    return {
      disabledContinue: false,
    };
  },
  components: {
    ButtonComponent: () => import("../components/general/Button.vue"),
  },
  computed: {
    ...mapState({
      closeModal: (state) => state.root.closeModal,
      env: (state) => state.environment.environment.env,
      affiliate: (state) => state.affiliate.affiliate,
      external_token: (state) => state.environment.environment.external_token,
    }),
    usersNames() {
      return localStorage.getItem("usersNames") ? JSON.parse(localStorage.getItem("usersNames")) : [];
    },
  },
  methods: {
    ...mapActions("root", [
      "changeTrip",
      "handleShowModal"
    ]),
    async handleSelectName(userName) {
      this.handleShowModal(loadingMessage({
        title: "Verificando documento...",
        state: "loading",
      }));
      try {
        const user = {
          afiliationState: true,
          serviceLine: "",
          name: "",
          email: "",
          lastname: "",
          phone: "",
          address: "",
        };
        this.disabledContinue = true;
        let data = {
          data: {
            "numero_documento": userName.documentNumber, 
            "tipo_documento": userName.id,
            // "ciudad_id:": userName.cityOrMunicipio,
            "eps_id": userName.eps.code
          },
          url: this.env.VUE_APP_EXTERNAL_SERVER + '/verify-beneficiary/client/1',
          token: this.external_token.access_token,
        }

        await this.fetchAffiliate(data);

        if (this.affiliate.resultado == 0) {
          this.handleShowModal({
            showModal: true,
            modalState: {
              buttonText: "Entendido",
              disabledContinue: false,
              showModal: true,
              responseState: "warning",
              responseToContinueTitle: "Falla en el servicio",
              responseToContinue: this.affiliate.resultado_descripcion
            },
          });
          return;
        }

        if (this.affiliate.resultado == 1) {
          if (!this.affiliate.beneficiario) {
            this.handleShowModal({
              showModal: true,
              modalState: {
                buttonText: "Entendido",
                disabledContinue: false,
                showModal: true,
                responseState: "warning",
                responseToContinueTitle: "Beneficiario no válido (inexistente o inactivo)",
                responseToContinue: this.affiliate.resultado_descripcion
              },
            });
            return;
          }
        }

        user.name = this.affiliate.beneficiario;

        this.changeUserData({
          dcType: data.data.tipo_documento,
          documentType: userName.documentType,
          documentNumber: data.data.numero_documento,
          cityOrMunicipio: userName.cityOrMunicipio,
          cityId: userName?.id,
          confirmCafam: userName.confirmCafam,
          ...user,
        });

        this.handleShowModal(loadingMessage({
          title: "Buscando citas...",
          state: "loading",
        }));
        await this.fetchAppointments({
          url: this.env.VUE_APPOINTMENTS_DEBMEDIA_SERVER,
          headers: {
            'token_appointments': this.env.VUE_APPOINTMENTS_DEBMEDIA_API_KEY,
          },
          queryData: '?customer.dni='+ this.userData.documentNumber
        });
        this.closeModal();
        if(this.appointments.length > 0){
            return this.changeTrip("Home_dates");
          }

        this.changeTrip("ValidationData");
      } catch (error) {
        console.error("[error: Falla en el servicio]:", error);
        this.handleShowModal(loadingMessage({
          title: "Lo sentimos ocurrió un error al validar tu documento",
          state: "danger",
        }));
        this.btn_text = 'Continuar';
      }
    },
    handleContinue() {
      this.changeTrip("Home");
    },
  },
};
</script>

<style scoped>

</style>